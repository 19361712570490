import React from 'react';

const App: React.FC = () => {
  return (
    <div className="App">
        <div className="terms-condition py-5">
            <div className="container">
            <h4>Terms And Conditions</h4>
                <p>Welcome to Sun Glow Internation ! These Terms and Conditions govern your use of our website and services. By accessing or using our platform, you agree to be bound by these terms. Please read them carefully before proceeding.</p>

                <h6>Intellectual Property:</h6>
                <p>All content, including text, graphics, logos, and images, is protected by copyright and intellectual property laws. You may not use or reproduce any material without our prior written consent.</p>

                <h6>User Responsibilities:</h6>
                <p>You are responsible for the accuracy of the information you provide and the content you upload.</p>
                <p>You agree not to engage in any illegal, unauthorized, or disruptive activities while using our platform.</p>

                <h6>Prohibited Activities:</h6>
                <p>You may not use our platform to upload or share content that infringes upon the rights of others, contains viruses or malware, or promotes hate speech, violence, or discrimination.</p>
                <p>Unauthorized use of our platform or any attempt to access restricted areas is strictly prohibited.</p>

                <h6>Third-Party Links:</h6>
                <p>Our platform may contain links to third-party websites. These links are provided for your convenience, and we do not endorse or assume any responsibility for the content or privacy practices of these websites.</p>

                <h6>Limitation of Liability:</h6>
                <p>We strive to provide accurate and reliable information, but we do not guarantee the completeness or accuracy of the content on our platform.</p>
                <p>We are not liable for any direct, indirect, incidental, consequential, or punitive damages arising from your use of our platform.</p>

                <h6>Modifications and Updates:</h6>
                <p>We reserve the right to modify, update, or discontinue any part of our platform at any time without prior notice.</p>
                <p>Changes to these Terms and Conditions will be posted on our website.</p>

                <h6>Governing Law:</h6>
                <p>These Terms and Conditions are governed by and construed in accordance with the laws of [Jurisdiction]. Any disputes arising out of these terms shall be subject to the exclusive jurisdiction of the courts in [Jurisdiction].</p>

                <p>If you have any questions or concerns regarding our Terms and Conditions, please contact us at <a href="mailto:info@sunglowbd.com">sunglowbd.com</a></p>
            </div>   
        </div>
    </div>
  );
}

export default App;