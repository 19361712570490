import React from 'react';

const App: React.FC = () => {
  return (
    <div className="App">
        <div className="dashboard-info py-5">
          <div className="container">
            <ul className="d-flex p-0">
              <li className="active"><i className="fa-solid fa-user"></i> <a href="#0" >Account Info</a></li>
              <li><i className="fa-solid fa-user"></i> <a href="#0">Orders</a></li>
              <li><i className="fa-solid fa-lock"></i> <a href="#0">Change Password</a></li>
              <li><i className="fa-solid fa-right-from-bracket"></i> <a href="#0">Logout</a></li>
            </ul>
            <div className="heading-border"></div>
            <div className="row d-flex justify-content-around">
              <div className="col-md-9">
                <div className="acc-bill-info my-5">
                  <h6>Account Info</h6>
                  <form className="row g-4">
                    <div className="col-md-12">
                      <input type="text" className="form-control input-design" placeholder="Abdul" id="inputPassword4"/>
                    </div>  
                    <div className="col-md-12">
                      <input type="text" className="form-control input-design" placeholder="Ali" id="inputPassword4"/>
                    </div>  
                    <div className="col-md-12">
                      <input type="email" className="form-control input-design" placeholder="abdulali@gmail.com *" id="inputPassword4"/>
                    </div>  
                    <div className="col-md-12">
                      <input type="text" className="form-control input-design" placeholder="017000000" id="inputPassword4"/>
                    </div>  
                    <div className="col-md-12">
                      <input type="text" className="form-control input-design" placeholder="Dhaka, Bangladesh" id="inputPassword4"/>
                    </div>  
                  </form>
                </div>
                <div className="acc-bill-info">
                  <h6>Billing Info</h6>
                  <form className="row g-4">
                    <div className="col-md-12">
                      <input type="text" className="form-control input-design" placeholder="Abdul" id="inputPassword4"/>
                    </div>  
                    <div className="col-md-12">
                      <input type="text" className="form-control input-design" placeholder="Ali" id="inputPassword4"/>
                    </div>  
                    <div className="col-md-12">
                      <input type="email" className="form-control input-design" placeholder="abdulali@gmail.com *" id="inputPassword4"/>
                    </div>  
                    <div className="col-md-12">
                      <input type="text" className="form-control input-design" placeholder="017000000" id="inputPassword4"/>
                    </div>  
                    <div className="col-md-12">
                      <input type="text" className="form-control input-design" placeholder="Dhaka, Bangladesh" id="inputPassword4"/>
                    </div>  
                  </form>
                </div>
                <div className=" my-4">
                   <a href="#0" className="theme-btn">Update</a>
                </div>
              </div>
              <div className="col-md-3 py-5">
                <div className="profile-img ">
                  <img src="assets/img/profile.png" alt="" />
                  <input type="text" className="form-control input-design my-4" placeholder="Upload Photo" id="inputPassword4" />
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
}

export default App;