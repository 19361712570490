import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import config from '../../config';
import { CategoryContext } from '../../components/CategoryContext';

interface ProductArray {
  id: number;
  name: string;
  slug: string;
  description: string;
  short_description: string;
  thumb: string;
  images: string[]; // assuming images are strings
  price: number | null; // assuming price can be null
  unit: string;
  is_featured: number;
  is_offered: number;
  category_id: number;
  user_id: number;
  status: string;
}

interface Category {
  id: number;
  name: string;
}

const App: React.FC = () => {
  const { categoryData, fetchCategoryData } = useContext(CategoryContext) || { categoryData: [], fetchCategoryData: () => {} };
  const [catDataById, setCatDataById] = useState<Category | null>(null);
  const [productArray, setProductArray] = useState<ProductArray[]>([]);
  const formatter = new Intl.NumberFormat('bn-BD');

  const fetchCategoryById = async (catObj: Category) => {
    try {
      const response = await axios.get<{ data: Category }>(config.apiUrl + 'category/' + catObj.id);    
      if (response.status === 200) {
        setCatDataById(response.data.data);
        fetchProductData();
      }
    } catch (error) {
      console.error('Error fetching category data:', error);
    }
  };

  const fetchProductData = async () => {
    try {
      let response;
      // console.log('_cat_id_p', catDataById?.id);
      if(catDataById?.id){
        // response = await axios.get<{ data: { data: ProductArray[] } }>(config.apiUrl+'product?category_id='+catDataById.id);
        // data pass into header
        response = await axios.get<{ data: { data: ProductArray[] } }>(config.apiUrl+'product', { headers: { 'category-id': catDataById.id.toString() } });
      }else{
        response = await axios.get<{ data: { data: ProductArray[] } }>(config.apiUrl+'product');
      }

      if (response.status === 200) {
        setProductArray(response.data.data.data);
        console.log('_product_data', response.data.data.data);

      } else {
        console.error('Failed to fetch product data');
      }
    } catch (error) {
      console.error('Error fetching product data:', error);
    }
  };

  useEffect(() => {
    fetchProductData();
    fetchCategoryData(); 
  }, []);
 

  return (
    <div className="App">
      <div className="product-page py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-3 col-sm-12">
              <p className="heading-text">ক্যাটাগরী </p>
              <div className="heading-border"></div>
              <div className="category-link">
                <ul className="p-0">
                  <li><a href="/product">সবগুলি </a></li>
                    {categoryData.map((categoryItem) => (
                      <li key={categoryItem.id}>
                        {/* call fetchCategoryById(categoryItem.id)  */}
                        <Link to={`/product/${categoryItem.id}`} onClick={() => fetchCategoryById(categoryItem)}>{categoryItem.name}</Link>
                        {/* <link to={`/product/${categoryItem.id}`}>{categoryItem.name}</link> */}
                      </li>
                    ))}
                </ul>
              </div>
            </div>
            <div className="col-md-9 col-sm-12">
              <p className="heading-text">
                {catDataById?.name ? catDataById.name : 'সবগুলি'}
              </p>
              <span></span>
              <div className="heading-border"></div>
              <section className="products-area card-design">
                    <div className="container">
                      <div className="row">
                      {Array.isArray(productArray) && productArray.map((product) => (
                       // product.is_featured==0 and productArray.is_offered==0 condition 
                          // product.is_featured==0 && product.is_offered==0 &&              
                            <div className="col-lg-4 col-md-4 col-sm-12 my-2 m-auto" key={product.id}>
                                <div className="card">
                                    <Link to={`/product-details/${product.id}`}>
                                        {/* <span className="stock-btn">Stock</span> */}
                                        <div className="card-image hover-overlay ripple" data-mdb-ripple-color="light">
                                            <img src={`${config.fileUrl1}${product.thumb}`} key={product.id} alt="" className="img-fluid p-2" />
                                        </div>
                                        <div className="card-body">
                                            <h6>{product.name}</h6>
                                            {/* cat: {product.category_id} */}
                                            <div className="price-cart d-flex justify-content-between">
                                                <ul className="price list-unstyled d-flex gap-2 m-0">
                                                    {product.price ? (<li>&#2547; {formatter.format(product.price)}</li>) : (<li>--</li>)}
                                                   
                                                </ul>
                                                <ul className="cart list-unstyled d-flex gap-2 m-0">
                                                    <li><a href="/cart"><i className="fa-solid fa-cart-plus"></i></a></li>
                                                    <li><a href="/cart"><i className="fa-regular fa-heart"></i></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;