import React, {useEffect, useState } from 'react';
import config from '../../../config';
import axios from 'axios';
import { Link } from 'react-router-dom';

interface ProductArray {
  id: number;
  name: string;
  slug: "";
  description: string;
  short_description: string;
  thumb: string;
  images: [];
  price: null;
  unit: string;
  is_top: 0;
  is_featured: 0;
  is_offered: 0 ;
  category_id: number;
  user_id: number;
  status: string;
}


const Product: React.FC = () => {

  const [productArray, setProductArray] = useState<ProductArray[]>([]);
  const formatter = new Intl.NumberFormat('bn-BD');

  const fetchProductData = async () => {
    try {
      const response = await axios.get<{ data: { data: ProductArray[] } }>(config.apiUrl+'product?size=100&page=1');
        
      if (response.status === 200) {
        setProductArray(response.data.data.data); 
        console.log('_product_list', response.data.data.data); 
      } else {
        console.error('Failed to fetch product data');
      }
    } catch (error) {
      console.error('Error fetching product data:', error);
    }
  };

  useEffect(() => {
    fetchProductData();
  }, []);

  return (
    <div className="App">
        <div className="products my-5 mb-5">
          <div className="container">
          <p className="heading-text">প্রোডাক্ট সমূহ </p>
          <div className="heading-border"></div> 
            <div className="row">
            {/* {Array.isArray(productArray) && productArray.map((product) => ( */}
              {Array.isArray(productArray) && productArray.map((product) => (
                product.is_top ? (
                             
                  <div className="col-lg-3 col-md-6 col-sm-12 my-2 m-auto" key={product.id}>
                      <div className="card">
                          <Link to={`/product-details/${product.id}`}>
                              {/* <span className="stock-btn">Stock</span> */}
                              <div className="card-image hover-overlay ripple" data-mdb-ripple-color="light">
                                  <img src={`${config.fileUrl1}${product.thumb}`} key={product.id} alt="" className="img-fluid p-2" />
                              </div>
                              <div className="card-body">
                                  <h6>{product.name}</h6>
                                  <div className="price-cart d-flex justify-content-between">
                                      <ul className="price list-unstyled d-flex gap-2 m-0">
                                          {product.price ? (<li>&#2547; {formatter.format(product.price)}</li>) : (<li>--</li>)}
                                      </ul>
                                      <ul className="cart list-unstyled d-flex gap-2 m-0">
                                          <li><a href="/cart"><i className="fa-solid fa-cart-plus"></i></a></li>
                                          <li><a href="/cart"><i className="fa-regular fa-heart"></i></a></li>
                                      </ul>
                                  </div>
                              </div>
                          </Link>
                      </div>
                  </div>
                ): ''))}
            </div>
          </div>
        </div>
    </div>
  );
};

export default Product;
