import React from 'react';

const App: React.FC = () => {
  return (
    <div className="App">
        <div className="login-page">
          <div className="container col-md-3">
              <div className="login-heading-text">
                <p className="heading-text-center text-center ">লগইন </p>
                <div className="heading-border text-center"></div>
              </div>
              <div className="reg-form">
                  <form className="row g-4">
                    <div className="col-md-12">
                      <input type="email" className="form-control input-design" placeholder="ইমেইল *"/>
                    </div>
                    <div className="col-md-12">
                      <input type="password" className="form-control input-design" placeholder="কন্ফার্ম পাসওয়ার্ড *"/>
                    </div>
                    <p className='m-0 my-2 mb-0'><a href="#0">পাসওয়ার্ড ভুলে গেছেন?</a></p>
                    <div className="login-btn">
                       <a href="/dashboard-info" type="submit" className="theme-btn">লগইন করুন </a>
                    </div>
                    <p>যদি অ্যাকাউন্ট না থাকে | <a href="/registration">নিবন্ধন করুন</a></p>
                  </form>
              </div>
          </div>
        </div>
    </div>
  );
}

export default App;