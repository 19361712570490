import React from 'react';

const App: React.FC = () => {
  return (
    <div className="App">
        <div className="shipping-details py-5">
          <div className="container">
            <p className="heading-text-center text-center">Shipping Details</p>
            <div className="heading-border"></div>
            <div className="row">
              <div className="col-md-9">
                {/* ----------------shipping address start---------------- */}
                <div className="shipping-address px-3">
                  <h6 className="py-4">Shipping Address</h6>
                  <form className="row g-4">
                    <div className="col-md-6">
                      <input type="text" className="form-control input-design" placeholder="First Name*" id="inputPassword4"/>
                    </div>
                    <div className="col-md-6">
                      <input type="text" className="form-control input-design" placeholder="Last Name" id="inputPassword4"/>
                    </div>
                    <div className="col-md-6">
                      <input type="email" className="form-control input-design" placeholder="Email*" id="inputPassword4"/>
                    </div>
                    <div className="col-md-6">
                      <input type="password" className="form-control input-design" placeholder="Phone*" id="inputPassword4"/>
                    </div>
                    <div className="col-12">
                      <input type="text" className="form-control input-design" id="inputAddress" placeholder="Address*"/>
                    </div>
                    <div className="col-md-6">
                      <input type="text" className="form-control input-design" placeholder="City*" id="inputPassword4"/>
                    </div>
                    <div className="col-md-6">
                      <input type="text" className="form-control input-design" placeholder="Postal Code*" id="inputPassword4"/>
                    </div>
                    <div className="col-12">
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" id="inlineFormCheck"/>
                        <label className="form-check-label" htmlFor="inlineFormCheck">
                            Create an account
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <input type="password " className="form-control input-design" placeholder="Password*" id="inputPassword4"/>
                    </div>
                    <div className="col-md-6">
                      <input type="password" className="form-control input-design" placeholder="Confirm Password*" id="inputPassword4"/>
                    </div>
                    <div className="col-md-12">
                      <div className="form-check p-0 d-flex">
                        <h6>Billing Address</h6>
                        <div className="ration-group">
                          <input className="form-check-input" type="radio" />
                          <label className="form-check-label" htmlFor="inlineFormCheck">
                            Same as shipping address
                          </label>
                        </div>
                        <div className="ration-group">
                          <input className="form-check-input" type="radio" />
                          <label className="form-check-label" htmlFor="inlineFormCheck">
                            Use a different billing address
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <input type="text" className="form-control input-design" placeholder="First Name*" id="inputPassword4"/>
                    </div>
                    <div className="col-md-6">
                      <input type="text" className="form-control input-design" placeholder="Last Name" id="inputPassword4"/>
                    </div>
                    <div className="col-md-6">
                      <input type="email" className="form-control input-design" placeholder="Email*" id="inputPassword4"/>
                    </div>
                    <div className="col-md-6">
                      <input type="password" className="form-control input-design" placeholder="Phone*" id="inputPassword4"/>
                    </div>
                    <div className="col-12">
                      <input type="text" className="form-control input-design" id="inputAddress" placeholder="Address*"/>
                    </div>
                    <div className="col-md-6">
                      <input type="text" className="form-control input-design" placeholder="City*" id="inputPassword4"/>
                    </div>
                    <div className="col-md-6">
                      <input type="text" className="form-control input-design" placeholder="Postal Code*" id="inputPassword4"/>
                    </div>
                  </form>
                </div>
                {/* ----------------shipping address end---------------- */}
              </div>
              <div className="col-md-3">
                {/* ----------------cart details start---------------- */}
                <div className="cart-details ">
                  <h6 className="py-3">Cart Details</h6>
                  <div className="cart-items">
                    <div className="added-item py-2">
                      <div className="d-flex justify-content-between align-items-center">
                        <img src="assets/img/offer/offer-1.png" alt="" />
                        <p className="m-0">Jergens Soothing Aloe</p>
                        <div className="product-price">6250/=</div>
                      </div>
                    </div>
                    <div className="added-item py-2">
                      <div className="d-flex justify-content-between align-items-center">
                        <img src="assets/img/offer/offer-3.png" alt="" />
                        <p className="m-0">English Breakfast</p>
                        <div className="product-price">480/=</div>
                      </div>
                    </div>
                    <div className="added-item py-2">
                      <div className="d-flex justify-content-between align-items-center">
                        <img src="assets/img/offer/offer-2.png" alt="" />
                        <p className="m-0">Twinings Early Grey </p>
                        <div className="product-price">1040/=</div>
                      </div>
                    </div>
                  </div>
                  <div className="custom-border my-3"></div>
                  <div className="cart-price-calculation my-4">
                    <div className="subtotal d-flex justify-content-between">
                        <p>Subtotal</p>
                        <h6>12450/=</h6>
                    </div>
                    <div className="coupon d-flex justify-content-between">
                       <p>Coupon</p>
                        <h6>150/=</h6>
                    </div>
                    <div className="total d-flex justify-content-between">
                        <p>Total</p>
                        <h6>12300/=</h6>
                    </div>
                  </div>
                  <div className="col-md-12 p-0">
                    <textarea
                      className="form-control input-design"
                      placeholder="Note"
                    />
                  </div>
                  <h6 className="py-4">Payment</h6>
                  <div className="col-md-12">
                      <input type="text" className="form-control input-design" placeholder="Cash on Delivery (COD)"/>
                  </div>
                  <div className="py-4">
                    <a href="/invoice" className="theme-btn w-100 my-5">Check Out</a>
                  </div>
                </div>
                {/* ----------------cart details end---------------- */}
              </div>
            </div>
          </div>
        </div>
    </div>
  );
}

export default App;