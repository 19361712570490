import axios  from 'axios';
import React, { useEffect, useState, useContext} from 'react';
import { Link } from 'react-router-dom';
import config from '../config';
import { CategoryContext } from '../components/CategoryContext';

interface SiteInfo {
    title: string;
    name: string;
    description: string;
    logo: string;
    icon: string;
    phone: string;
    email: string;
    address: string;
    map_html: string;
    copyright_text: string;
}

const Header: React.FC = () => {
    // hooks
    const [siteInfo, setSiteInfo] = useState<SiteInfo | null>(null);
    const [isToggled, setIsToggled] = useState(false);
    const { categoryData, fetchCategoryData } = useContext(CategoryContext) || { categoryData: [], fetchCategoryData: () => {} };

    
    //function for siteinfo
    const fetchSiteInfo = async () => {
        try {
            const response = await axios.get<{ data: SiteInfo }>(config.apiUrl+'siteinfo');
            if (response.status === 200) {
                setSiteInfo(response.data.data);    
                console.log('siteinfo',siteInfo);    
            } else {
                console.error('Failed to fetch site information');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const handleToggle = () => {
        setIsToggled((prev) => !prev);
    };
    //       let allCategoryData: Category[] = [];
    //       let currentPage = 1;
      
    //       while (true) {
    //         const response = await axios.get<{ data: { data: Category[] } }>(
    //           `${config.apiUrl}category?page=${currentPage}`
    //         );
    //         if (response.status === 200) {
    //           const responseData = response.data.data;
    //           if (responseData.data.length === 0) {
    //             break;
    //           }
      
    //           // Concatenate the current page's data to the overall array
    //           allCategoryData = [...allCategoryData, ...responseData.data];
      
    //           currentPage++;
    //         } else {
    //           console.error('Failed to fetch site information');
    //           break;
    //         }
    //       }
      
    //       // Set the state with all the data
    //       setCategory(allCategoryData);
    //     } catch (error) {
    //       console.error('Error fetching data:', error);
    //     }
    //   };

    useEffect(() => {
        fetchSiteInfo(); 
        if (fetchCategoryData()) {
            // fetchCategoryData(); 
            console.log('categoryData_header', categoryData);
        }     
              
    },[]); 
    
    return (
        <div className="App">
            <div className="header">
                <section className="header-top">
                    <div className="container">
                        <div className="manu-area d-flex justify-content-between align-items-center">
                         <button onClick={handleToggle} className='d-xl-none d-block menu-open-btn'><img src="assets/img/menu-icon.png" alt="" /></button>
                            <div className="contact w-xl-100">
                                {siteInfo && (
                                    <Link to={`tel: ${siteInfo && siteInfo.phone}`}>
                                    <i className="fa-solid fa-phone"></i>
                                    {siteInfo && siteInfo.phone}
                                </Link>
                                )}                           
                            </div>  
                            <div className="navmanu w-xl-100 h-0 w-100" >
                                <div className={`navlist p-0 m-0${isToggled ? ' open' : ''}`}>
                                    <div className="close-btn d-flex justify-content-end d-xl-none">                                        
                                        <button onClick={handleToggle} className='d-xl-none d-block close-btn-img'><img src="assets/img/menu-icon-close.png" alt="" /></button>
                                    </div>  
                                    <ul className='nav-menu-list'>
                                        <li><Link to="/">হোম</Link></li>
                                        {/* <li><Link to="/brands">ব্রান্ড</Link></li> */}
                                        <li>
                                            <Link to="/product" className="accordion-icon">পণ্য <i className="fa-solid fa-sort-down"></i></Link>
                                            <div className="dropdown">
                                                <div className="row">
                                                    {categoryData.map((category, index) => (
                                                        <div key={index} className="col-xl-3 col-sm-12">
                                                            <ul>
                                                                <li key={index}>
                                                                    {/* <Link to={`/product/${category.name.toUpperCase()}`}>{category.name}</Link> */}
                                                                    <Link to={`/product/${category.id}`}>{category.name}</Link>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </li>
                                        <li><Link to="/blog">ব্লগ</Link></li>
                                        <li><Link to="/contact-us">যোগাযোগ করুন</Link></li>
                                    </ul>                         
                                </div>
                            </div>
                            <div className="shiping-content d-flex justify-content-end w-xl-100">
                                <p className="mb-0 word-wrap"><i className="fa-solid fa-truck-fast"></i></p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="header-bottom py-2">
                    <div className="container">
                        <div className="second-menu d-flex justify-content-between align-items-center">
                            <div className="logo">
                                <Link to="/"><img className="img-fluid" src={`${config.fileUrl}logo.png`} alt="" /></Link>
                            </div>                      
                            <div className="input-box d-flex align-items-center justify-content-center  h-100">
                                <input type="text" className="form-control input-design" placeholder="আপনার পণ্যটি  খুজুন ....." />
                                <i className="fa-solid fa-magnifying-glass"></i>
                            </div>
                            <div className="header-icon d-flex align-items-center justify-content-end h-100">
                                <Link to="/login"><i className="fa-regular fa-user"></i></Link>
                                {/* <Link to="/favorite"><i className="fa-regular fa-heart"></i></Link> */}
                                <Link to="/cart"><i className="fa-solid fa-cart-plus"></i></Link>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <hr className="m-0" />
        </div>
    );
}

export default Header;
