import React from 'react';

const App: React.FC = () => {
  return (
    <div className="App">
        <div className="registration-page py-5">
          <div className="container col-md-3">
              <p className="heading-text-center text-center">নিবন্ধন </p>
              <div className="heading-border text-center"></div>
              <div className="reg-form">
                  <form className="row g-4">
                    <div className="col-md-12"> 
                      <input type="text" className="form-control input-design" placeholder="নাম*"/>
                    </div>
                    <div className="col-md-12">
                      <input type="email" className="form-control input-design" placeholder="ই-মেইল*"/>
                    </div>
                    <div className="col-md-12">
                      <input type="text" className="form-control input-design" placeholder="ফোন*"/>
                    </div>
                    <div className="col-md-12">
                      <input type="text" className="form-control input-design"  placeholder="ঠিকানা*"/>
                    </div>
                    <div className="col-md-12">
                      <input type="password" className="form-control input-design" placeholder="কনফার্ম পাসওয়ার্ড*"/>
                    </div>
                    <p>ইতিমধ্যে এবং অ্যাকাউন্ট আছে | <a href="/login">লগইন</a></p>
                    <button type="submit" className="theme-btn">নিবন্ধন করুন</button>
                  </form>
              </div>
          </div>
        </div>
    </div>
  );
}

export default App;