import React, { useEffect, useState } from 'react';
// import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import "swiper/css";
import 'swiper/css/pagination';
import config from '../../../config';
import axios from 'axios';
import { Link } from 'react-router-dom';

interface OfferedProductArray {
  id: number;
  name: string;
  slug: "";
  description: string;
  short_description: string;
  thumb: string;
  images: [];
  price: null;
  unit: string;
  is_top: 0;
  is_featured: 0;
  is_offered: 0 ;
  category_id: number;
  user_id: number;
  status: string;
}

const App: React.FC = () => {

  const [OfferedProductArray, setOfferedProductArray] = useState<OfferedProductArray[]>([]);
  const formatter = new Intl.NumberFormat('bn-BD');

  const fetchProductData = async () => {
    try {
      const response = await axios.get<{ data: { data: OfferedProductArray[] } }>(config.apiUrl+'product');
        
      if (response.status === 200) {
        setOfferedProductArray(response.data.data.data); 
        console.log('_product_list', response.data.data.data); 
      } else {
        console.error('Failed to fetch product data');
      }
    } catch (error) {
      console.error('Error fetching product data:', error);
    }
  };

  useEffect(() => {
    fetchProductData();
  }, []);

  return (
    <div className="App">
      {/* ------------offer products start---------- */}
      <section className="offer-products py-2">
        <div className="container">
          <p className="heading-text">  অফার প্রোডাক্ট</p>
          <div className="heading-border"></div> 
          <div className="row">
          {Array.isArray(OfferedProductArray) && OfferedProductArray.map((OfferedProduct) => (
                // product.is_featured==1 and OfferedProductArray.is_offered==1 condition 
                OfferedProduct.is_featured ? (
                  <div className="col-md-6 col-sm-12 my-3" key={OfferedProduct.id}>
                    <div className="offer-product-left">
                    <Link to={`/product-details/${OfferedProduct.id}`}>
                      <div className="card">                          
                            {/* <span className="offer-btn">Stock</span> */}
                            <div className="card-image hover-overlay ripple" data-mdb-ripple-color="light">
                                <img src={`${config.fileUrl1}${OfferedProduct.thumb}`} key={OfferedProduct.id} alt="" className="img-fluid p-2" />
                            </div>
                            <div className="card-body">
                                <h6>{OfferedProduct.name}</h6>
                                <div className="price-cart d-flex justify-content-between">
                                    <ul className="price list-unstyled d-flex gap-2 m-0">
                                        {OfferedProduct.price ? (<li>&#2547; {formatter.format(OfferedProduct.price)}</li>) : (<li>--</li>)}
                                    </ul>
                                    <ul className="cart list-unstyled d-flex gap-2 m-0">
                                        <li><a href="/cart"><i className="fa-solid fa-cart-plus"></i></a></li>
                                        <li><a href="/cart"><i className="fa-regular fa-heart"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                      </div>                      
                      </Link>
                    </div>
                  </div>
            ): ''))}

            <div className="col-md-6 col-sm-12">
              <div className="offer-product-right">
                <div className="row">
                {Array.isArray(OfferedProductArray) && OfferedProductArray.map((OfferedProduct) => (
                // OfferedProductArray.is_offered == 1
                OfferedProduct.is_offered ? (
                  <div className="col-md-6 col-sm-12 my-3">
                    <Link to={`/product-details/${OfferedProduct.id}`}>
                     <div className="card">
                        {/* <span className="offer-btn">Stock</span> */}
                        <div className="card-image hover-overlay ripple" data-mdb-ripple-color="light">
                          {/* <img src={`${config.fileUrl}products/product-1.jpg`} alt="" className="img-fluid p-2"/> */}
                          <img src={`${config.fileUrl1}${OfferedProduct.thumb}`} key={OfferedProduct.id} alt="" className="img-fluid p-2" />
                        </div>
                        <div className="card-body">
                          <h6>{OfferedProduct.name}</h6>
                          <div className="price-cart d-flex justify-content-between">
                              <ul className="price list-unstyled d-flex gap-2 m-0">
                                {OfferedProduct.price ? (<li>&#2547; {formatter.format(OfferedProduct.price)}</li>) : (<li>--</li>)}
                              </ul>
                            <ul className="cart list-unstyled d-flex gap-2 m-0">
                              <li><a href="/cart"><i className="fa-solid fa-cart-plus"></i></a></li>
                              <li><a href="/cart"><i className="fa-regular fa-heart"></i></a></li>
                            </ul>
                          </div> 
                        </div>
                      </div>
                    </Link>
                  </div>
                 ):'' ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ------------offer products start---------- */}

      {/* --------------Best Selling part start--------- */}
      {/* --------------New Product part start--------- */}
      <section className="best-selling-part card-design py-5">
        <div className="container">
          <p className="heading-text">নতুন প্রোডাক্ট সমূহ</p>
          <div className="heading-border"></div> 
          <div className="row">
              {/* product.is_top?0:product.is_featured?0:product.is_offered?0: */}
              {Array.isArray(OfferedProductArray) && OfferedProductArray.map((OfferedProduct) => (
                // is_top=0, is_fetured=0, is_offered=0 then 
                OfferedProduct.is_top == 0 && OfferedProduct.is_featured == 0 && OfferedProduct.is_offered == 0 ? (                
                  <div className="col-lg-3 col-md-4 col-sm-12 my-3">
                    <Link to={`/product-details/${OfferedProduct.id}`}>
                     <div className="card">
                        {/* <span className="offer-btn">Stock</span> */}
                        <div className="card-image hover-overlay ripple" data-mdb-ripple-color="light">
                          {/* <img src={`${config.fileUrl}products/product-1.jpg`} alt="" className="img-fluid p-2"/> */}
                          <img src={`${config.fileUrl1}${OfferedProduct.thumb}`} key={OfferedProduct.id} alt="" className="img-fluid p-2" />
                        </div>
                        <div className="card-body">
                          <h6>{OfferedProduct.name}</h6>
                          <div className="price-cart d-flex justify-content-between">
                              <ul className="price list-unstyled d-flex gap-2 m-0">
                                {OfferedProduct.price ? (<li>&#2547; {formatter.format(OfferedProduct.price)}</li>) : (<li>--</li>)}
                              </ul>
                            <ul className="cart list-unstyled d-flex gap-2 m-0">
                              <li><a href="/cart"><i className="fa-solid fa-cart-plus"></i></a></li>
                              <li><a href="/cart"><i className="fa-regular fa-heart"></i></a></li>
                            </ul>
                          </div> 
                        </div>
                      </div>
                    </Link>
                  </div>
                 ):''))}



            <div className=" my-2 text-end">
              <a href="/product" className="theme-btn btn-sm">আরও দেখুন</a>
            </div>
          </div>
        </div>
      </section>
      {/* --------------New Product part start--------- */}
      {/* --------Category Bootom Start-------- */}
    </div>
  );
}

export default App;