import React from 'react';

const App: React.FC = () => {
  return (
    <div className="App">
        <div className="privacy-policy py-5">
            <div className="container">
                <h4>Privacy Policy</h4>
                <p>At Sun Glow Internation, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, disclose, and safeguard the information you provide to us. By using our website and services, you consent to the terms of this policy.</p>

                <h6>Information We Collect:</h6>
                <ul>
                <li>Personal information such as your name, email address, and contact details may be collected when you interact with our website or services.</li>
                <li>Non-personal information such as your IP address, browser type, and device information may be automatically collected for analytical purposes.</li>
                </ul>

                <h6>How We Use Your Information:</h6>
                <p>We use your personal information to provide and improve our services, respond to inquiries, and personalize your experience. Non-personal information is used for statistical analysis, troubleshooting, and website optimization.</p>

                <h6>Information Sharing:</h6>
                <p>We may share your personal information with trusted third-party service providers who assist us in delivering our services. We do not sell, rent, or trade your personal information to third parties for marketing purposes.</p>

                <h6>Data Security:</h6>
                <p>We implement appropriate security measures to protect your information from unauthorized access, alteration, or disclosure. However, please note that no method of transmission over the internet or electronic storage is completely secure.</p>

                <h6>Your Choices:</h6>
                <ul>
                <li>You have the right to access, correct, or delete your personal information held by us.</li>
                <li>You may opt-out of receiving promotional communications from us at any time.</li>
                </ul>

                <h6>Updates to Privacy Policy:</h6>
                <p>We may update our Privacy Policy from time to time. The updated policy will be posted on our website.</p>

                <h6>Contact Us:</h6>
                <p>If you have any questions or concerns regarding our Privacy Policy, please contact us at <a href="mailto:info@sunglowbd.com">sunglowbd.com</a>.</p>
            </div>   
        </div>
    </div>
  );
}

export default App;