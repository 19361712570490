import React from 'react';
import Slider from './Sections/Slider';
import Category from './Sections/Category';
import Products from './Sections/Products';
import OfferProducts from './Sections/OfferProducts';
// import Form from './Sections/Form';
const App: React.FC = () => {
  
  return (
    <div className="App">
      <Slider />
      <Category/>
      <Products/>
      <OfferProducts/>
      {/* <Form/> */}
    </div>
  );
}

export default App;