import React from 'react';

const Blog: React.FC = () => {
  return (    
    <div className="App">
            {/* <!-- blog area start--> */}
      <section className="blog-page-area py-5">
          <div className="container">
            <p className="heading-text-center text-center">ব্লগ</p>
            <div className="heading-border"></div>
              <div className="row">
                  <div className="col-lg-9 col-md-9 col-sm-12 mx-auto">
                      <div className="blog-right-are">
                        <div className="row">
                            <div className="input-group mb-3">
                                <input type="text" className="form-control input-design " placeholder="ব্লগ খোঁজ  করুন ..."
                                    aria-label="Recipient's username" aria-describedby="basic-addon2"/>
                                <a href="#0" className=" theme-btn" type="submit" id="basic-addon2">জমা দিন</a>
                            </div>
                            <div className="col-md-4 my-2">
                                <div className="blog-card">
                                    <div className="blog-image">
                                        <img src="assets/img/blog/blog-1.jpg" alt="n/a" className="img-fluid"/>
                                    </div>
                                    <div className="blog-content">
                                        <div className="content-title"><a href="#0">
                                                <p className="p-0">শৈত্য প্রবাহ থেকে বোর ধানের চারা রক্ষায় করনীয়</p>
                                            </a>
                                        </div>
                                        <div className="content-excerpt">
                                            <p >সারাদেশে শৈত্য প্রবাহ চলছে।এই প্রতিকূল আবহাওয়া থেকে বোর ধানের চারা রক্ষায় নিচের পদক্ষেপগুলো অনুসরণ করুন.</p>
                                        </div>
                                        <div className="blog-author d-flex justify-content-between">
                                            <a href="#0"> <i className="fa-solid fa-user"></i> ফাহাদ হাসান</a><a href="#0"> <i
                                                    className="fa-solid fa-calendar-days"></i> ১০ মার্চ, ২০২৪</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 my-2">
                                <div className="blog-card">
                                    <div className="blog-image">
                                        <img src="assets/img/blog/blog-1.jpg" alt="n/a" className="img-fluid"/>
                                    </div>
                                    <div className="blog-content">
                                        <div className="content-title"><a href="#0">
                                                <p className="p-0">শৈত্য প্রবাহ থেকে বোর ধানের চারা রক্ষায় করনীয়</p>
                                            </a>
                                        </div>
                                        <div className="content-excerpt">
                                            <p >সারাদেশে শৈত্য প্রবাহ চলছে।এই প্রতিকূল আবহাওয়া থেকে বোর ধানের চারা রক্ষায় নিচের পদক্ষেপগুলো অনুসরণ করুন.</p>
                                        </div>
                                        <div className="blog-author d-flex justify-content-between">
                                            <a href="#0"> <i className="fa-solid fa-user"></i> ফাহাদ হাসান</a><a href="#0"> <i
                                                    className="fa-solid fa-calendar-days"></i> ১০ মার্চ, ২০২৪</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 my-2">
                                <div className="blog-card">
                                    <div className="blog-image">
                                        <img src="assets/img/blog/blog-1.jpg" alt="n/a" className="img-fluid"/>
                                    </div>
                                    <div className="blog-content">
                                        <div className="content-title"><a href="#0">
                                                <p className="p-0">শৈত্য প্রবাহ থেকে বোর ধানের চারা রক্ষায় করনীয়</p>
                                            </a>
                                        </div>
                                        <div className="content-excerpt">
                                            <p >সারাদেশে শৈত্য প্রবাহ চলছে।এই প্রতিকূল আবহাওয়া থেকে বোর ধানের চারা রক্ষায় নিচের পদক্ষেপগুলো অনুসরণ করুন.</p>
                                        </div>
                                        <div className="blog-author d-flex justify-content-between">
                                            <a href="#0"> <i className="fa-solid fa-user"></i> ফাহাদ হাসান</a><a href="#0"> <i
                                                    className="fa-solid fa-calendar-days"></i> ১০ মার্চ, ২০২৪</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 my-2">
                                <div className="blog-card">
                                    <div className="blog-image">
                                        <img src="assets/img/blog/blog-1.jpg" alt="n/a" className="img-fluid"/>
                                    </div>
                                    <div className="blog-content">
                                        <div className="content-title"><a href="#0">
                                                <p className="p-0">শৈত্য প্রবাহ থেকে বোর ধানের চারা রক্ষায় করনীয়</p>
                                            </a>
                                        </div>
                                        <div className="content-excerpt">
                                            <p >সারাদেশে শৈত্য প্রবাহ চলছে।এই প্রতিকূল আবহাওয়া থেকে বোর ধানের চারা রক্ষায় নিচের পদক্ষেপগুলো অনুসরণ করুন.</p>
                                        </div>
                                        <div className="blog-author d-flex justify-content-between">
                                            <a href="#0"> <i className="fa-solid fa-user"></i> ফাহাদ হাসান</a><a href="#0"> <i
                                                    className="fa-solid fa-calendar-days"></i> ১০ মার্চ, ২০২৪</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 my-2">
                                <div className="blog-card">
                                    <div className="blog-image">
                                        <img src="assets/img/blog/blog-1.jpg" alt="n/a" className="img-fluid"/>
                                    </div>
                                    <div className="blog-content">
                                        <div className="content-title"><a href="#0">
                                                <p className="p-0">শৈত্য প্রবাহ থেকে বোর ধানের চারা রক্ষায় করনীয়</p>
                                            </a>
                                        </div>
                                        <div className="content-excerpt">
                                            <p >সারাদেশে শৈত্য প্রবাহ চলছে।এই প্রতিকূল আবহাওয়া থেকে বোর ধানের চারা রক্ষায় নিচের পদক্ষেপগুলো অনুসরণ করুন.</p>
                                        </div>
                                        <div className="blog-author d-flex justify-content-between">
                                            <a href="#0"> <i className="fa-solid fa-user"></i> ফাহাদ হাসান</a><a href="#0"> <i
                                                    className="fa-solid fa-calendar-days"></i> ১০ মার্চ, ২০২৪</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 my-2">
                                <div className="blog-card">
                                    <div className="blog-image">
                                        <img src="assets/img/blog/blog-1.jpg" alt="n/a" className="img-fluid"/>
                                    </div>
                                    <div className="blog-content">
                                        <div className="content-title"><a href="#0">
                                                <p className="p-0">শৈত্য প্রবাহ থেকে বোর ধানের চারা রক্ষায় করনীয়</p>
                                            </a>
                                        </div>
                                        <div className="content-excerpt">
                                            <p >সারাদেশে শৈত্য প্রবাহ চলছে।এই প্রতিকূল আবহাওয়া থেকে বোর ধানের চারা রক্ষায় নিচের পদক্ষেপগুলো অনুসরণ করুন.</p>
                                        </div>
                                        <div className="blog-author d-flex justify-content-between">
                                            <a href="#0"> <i className="fa-solid fa-user"></i> ফাহাদ হাসান</a><a href="#0"> <i
                                                    className="fa-solid fa-calendar-days"></i> ১০ মার্চ, ২০২৪</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                       <div className="d-flex justify-content-center">
                            <a href="#0" className="theme-btn my-2">আরও পড়ুন...</a>
                       </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>
    {/* <!-- blog area end--> */}
    </div>
  );
}

export default Blog;