// src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/HomePage/Home';
import AboutUs from './components/AboutUsPage/AboutUs';
import Product from './components/ProductPage/Product';
import Contact from './components/ContactPage/Contact';
import Brand from './components/BrandPage/Brand';
import ProductDetails from './components/ProductPage/ProductDetails';
import Cart from './components/CartPage/Cart';
import CartCheckout from './components/CartPage/Checkout';
import Invoice from './components/OrdersPage/Invoice';
import OrderTrackInvoice from './components/OrdersPage/OrderTrackInvoice';
import Registartion from './components/AccountInfoPage/Registration';
import Login from './components/AccountInfoPage/Login';
import Password from './components/AccountInfoPage/Password';
import DashboardInfo from './components/AccountInfoPage/DashboardInfo';
import UpdateProfile from './components/AccountInfoPage/UpdateProfile';
import DashboardOrder from './components/AccountInfoPage/DashboardOrder';
import CallToAction from './components/HomePage/Sections/CallToAction';
import TermsConditions from './components/PrivacyAndTerms/TermsCondition';
import PrivacyPolicy from './components/PrivacyAndTerms/PrivacyPolicy';
import Header from './components/Header';
import Footer from './components/Footer';
import Blog from './components/BlogPage/Blog';
import BlogDetails from './components/BlogPage/BlogDetails';

const App: React.FC = () => {
  return (
    <Router>
      <div>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/product" element={<Product />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/product/:categoryname" element={<Product />} />
          <Route path="/product-details/:id" element={<ProductDetails />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog-details" element={<BlogDetails />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/checkout" element={<CartCheckout />} />
          <Route path="/invoice" element={<Invoice />} />
          <Route path="/order-track-invoice" element={<OrderTrackInvoice />} />
          <Route path="/registration" element={<Registartion />} />
          <Route path="/login" element={<Login />} />
          <Route path="/password" element={<Password />} />
          <Route path="/dashboard-info" element={<DashboardInfo />} />
          <Route path="/update-profile" element={<UpdateProfile />} />
          <Route path="/dashboard-order" element={<DashboardOrder />} />
          <Route path="/call-to-action" element={<CallToAction />} />
          <Route path="/brands" element={<Brand />} />
          <Route path="/terms-conditions" element={<TermsConditions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

export default App;