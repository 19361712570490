import React from 'react';

const App: React.FC = () => {
  return (
    <div className="App">
        <div className="password info-nav py-5">
          <div className="container">
            <ul className="d-flex p-0">
              <li className="active"><i className="fa-solid fa-user"></i> <a href="#0" >Account Info</a></li>
              <li><i className="fa-solid fa-user"></i> <a href="#0">Orders</a></li>
              <li><i className="fa-solid fa-lock"></i> <a href="#0">Change Password</a></li>
              <li><i className="fa-solid fa-right-from-bracket"></i> <a href="#0">Logout</a></li>
            </ul>
            <div className="heading-border"></div>
            <div className="container col-md-4 py-4">
              <h6 className="py-4">Account Info</h6>
              <div className="reg-form">
                  <form className="row g-4">
                    <div className="col-md-12">
                      <input type="password" className="form-control input-design" placeholder="Current password*"/>
                    </div>
                    <div className="col-md-12">
                      <input type="password" className="form-control input-design" placeholder="New Password*"/>
                    </div>
                    <div className="col-md-12">
                      <input type="password" className="form-control input-design" placeholder="Confirm Password*"/>
                    </div>
                    <div className="col-md-12">
                      <a href="#0" className="theme-btn">Update</a>
                    </div>
                  </form>
              </div>
            </div>

          </div>
        </div>
    </div>
  );
}

export default App;