import React from 'react';

const App: React.FC = () => {
  return (
    <div className="App">
        <div className="dashboard-order py-5">
          <div className="container">
            <ul className="d-flex p-0">
              <li className="active"><i className="fa-solid fa-user"></i> <a href="#0" >Account Info</a></li>
              <li><i className="fa-solid fa-user"></i> <a href="#0">Orders</a></li>
              <li><i className="fa-solid fa-lock"></i> <a href="#0">Change Password</a></li>
              <li><i className="fa-solid fa-right-from-bracket"></i> <a href="#0">Logout</a></li>
            </ul>
            <div className="heading-border"></div>
            <div className="table table-responsive py-4">
                <table className="table">
                  <thead className="thead w-100">
                    <tr className="text-left">
                      <th className="col-2">Order Id</th>
                      <th className="col-2 text-center">Date</th>
                      <th className="col-2 text-center">Total</th>
                      <th className="col-2 text-center">Status</th>
                      <th className="col-2 text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody  className="tbody text-left">
                    <tr className="py-5 justify-content-center align-items-center border-bottom">
                      <td>#00540680</td>
                      <td className="text-center">05/11/2023</td>
                      <td className="text-center">1250</td>
                      <td className="text-center">Processing</td>
                      <td className="text-center"><a href="#0" className="theme-btn">View</a></td>
                    </tr>
                    <tr className="py-5 justify-content-center align-items-center border-bottom">
                      <td>#00540680</td>
                      <td className="text-center">05/11/2023</td>
                      <td className="text-center">1250</td>
                      <td className="text-center">Processing</td>
                      <td className="text-center"><a href="#0" className="theme-btn">View</a></td>
                    </tr>
                    <tr className="py-5 justify-content-center align-items-center border-bottom">
                      <td>#00540680</td>
                      <td className="text-center">05/11/2023</td>
                      <td className="text-center">1250</td>
                      <td className="text-center">Processing</td>
                      <td className="text-center"><a href="#0" className="theme-btn">View</a></td>
                    </tr>
                    <tr className="py-5 justify-content-center align-items-center border-bottom">
                      <td>#00540680</td>
                      <td className="text-center">05/11/2023</td>
                      <td className="text-center">1250</td>
                      <td className="text-center">Processing</td>
                      <td className="text-center"><a href="#0" className="theme-btn">View</a></td>
                    </tr>
                  </tbody>
                </table>

            </div>
          </div>
        </div>
    </div>
  );
}

export default App;