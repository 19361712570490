import React from 'react';

const App: React.FC = () => {
  return (
    <div className="App">
        <div className="dashboard-info info-nav py-5">
          <div className="container">
            <ul className="d-flex p-0">
              <li className="active"><i className="fa-solid fa-user"></i> <a href="#0" >Account Info</a></li>
              <li><i className="fa-solid fa-user"></i> <a href="#0">Orders</a></li>
              <li><i className="fa-solid fa-lock"></i> <a href="#0">Change Password</a></li>
              <li><i className="fa-solid fa-right-from-bracket"></i> <a href="#0">Logout</a></li>
            </ul>
            <div className="heading-border"></div>
            <div className="row d-flex justify-content-around">
              <div className="col-md-9">
                <div className="acc-bill-info my-5">
                  <h6>Account Info</h6>
                  <div className="items d-flex p-0 m-0">
                    <p ><i className="fa-solid fa-user"></i>Name:</p> <span>John Doe</span>
                  </div>
                  <div className="items d-flex p-0 m-0">
                    <p><i className="fa-solid fa-envelope"></i>Email:</p> <span>John Doe</span>
                  </div>
                  <div className="items d-flex p-0 m-0">
                    <p><i className="fa-solid fa-phone"></i>Phone:</p> <span>John Doe</span>
                  </div>
                  <div className="items d-flex p-0 m-0">
                    <p><i className="fa-solid fa-address-book"></i>Address:</p> <span>John Doe</span>
                  </div>
                </div>
                <div className="acc-bill-info">
                  <h6>Billing Info</h6>
                  <div className="items d-flex p-0 m-0">
                    <p ><i className="fa-solid fa-user"></i>Name:</p> <span>John Doe</span>
                  </div>
                  <div className="items d-flex p-0 m-0">
                    <p><i className="fa-solid fa-envelope"></i>Email:</p> <span>John Doe</span>
                  </div>
                  <div className="items d-flex p-0 m-0">
                    <p><i className="fa-solid fa-phone"></i>Phone:</p> <span>John Doe</span>
                  </div>
                  <div className="items d-flex p-0 m-0">
                    <p><i className="fa-solid fa-address-book"></i>Address:</p> <span>John Doe</span>
                  </div>
                </div>
                <div className="py-4">
                   <a href="/update-profile " className="theme-btn">Edit</a>
                </div>
              </div>
              <div className="col-md-3 py-5">
                <div className="profile-img ">
                  <img src="assets/img/profile.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
}

export default App;